




import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "coaches"
})
export default class Coaches extends Vue {}
